<template>
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <el-select v-model="eligibleType" placeholder="应标资格" clearable @change="onCurrentPage">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 标期搜索 -->
        <el-select v-model="bidPeriod" filterable placeholder="请选择标期搜索" @change="onCurrentPage" clearable>
          <el-option v-for="item in options" :key="item.tendersId" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入标号" suffix-icon="el-icon-search" v-model="tendersLabel" :clearable="true"
          @change="onCurrentPage"></el-input>
        <el-input placeholder="请输入标书名称" suffix-icon="el-icon-search" v-model="tendersName" :clearable="true"
          @change="onCurrentPage"></el-input>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersName" label="招标名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="createdTime" label="发布时间" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersType" label="招标类型" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                <!--招标类型 (1:商超) -->
                {{ scope.row.tendersType == 1 ? '商超' : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="eligibleType" label="应标资格" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div>
                <!-- 资格类型：1平台资格；2独标资格 -->
                {{ scope.row.eligibleType == 1 ? '平台资格' : '独立标资格' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tendersNumber" label="最大投标商品数" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="depositAmount" label="履约保证金(元)" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="tendersStatus" label="标书状态" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <!-- 标书状态(0:招标中,1:评标中 2:已评标)"-->
              <div :style="{ color: scope.row.tendersStatus == 1 ? '#E35B5A' : '' }">
                {{ scope.row.tendersStatus == 0 ? '招标中' : scope.row.tendersStatus == 1 ? '评标中' : '已评标' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="缴费状态" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div :style="{ color: getStatusColor(scope.row.depositStatus) }">
                {{ getStatusText[scope.row.depositStatus] }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="缴纳截止日期" width="200" align="center" fixed="right"> -->
          <el-table-column label="缴纳截止日期" min-width="130" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.deadlinePayTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right" align="center" min-width="160">
            <template slot-scope="scope">
              <el-button v-if="scope.row.tendersStatus == 2 && scope.row.depositStatus == 0" type="primary" size="small"
                @click="handleCommand(scope.row)">缴费</el-button>
              <el-button type="primary" size="small" plain @click="handleCommandPay(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
    <!-- 手机验证码 -->
    <child :showFlag="showFlag" @closeChildDialog="closeChildDialog"></child>
  </div>
</template>

<script>
import child from './detail/paymentDet.vue'

export default {
  components: {
    child,
  },
  data() {
    return {
      loading: false,
      showFlag: false,
      selectPage: 0,//标期分页
      selectLoading: false,
      options: [], //选择标期--一级
      hasMore: true,
      selectValue: null,
      // 招标信息数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 缴费状态0待支付；1已支付）
      bidStatus: [
        {
          value: '0',
          label: '未缴费'
        }, {
          value: '1',
          label: '已缴费'
        },
      ],
      //应标资格
      selectData: [{
        value: '1',
        label: '平台资格'
      }, {
        value: '2',
        label: '独立标资格'
      }],
      getStatusText: {
        0: '未缴费',
        1: '已缴费'
      },

      eligibleType: '',//应标资格
      bidPeriod: '',//标期
      tendersName: '',//标书名称
      tendersLabel: '',//标号
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.bidPeriod = obj?.bidPeriod
    this.tendersName = obj?.tendersName
    this.eligibleType = obj?.eligibleType
    this.tendersLabel = obj?.tendersLabel
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productList", {})
    // 获取列表
    this.getRequest()
    this.getBidPeriod()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendMyWinTenders, {
        params: {
          bidPeriod: this.bidPeriod || null,//标期
          eligibleType: this.eligibleType || null,//应标资格
          tendersLabel: this.tendersLabel || null,//标号
          tendersName: this.tendersName || null,//标书名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 标期列表请求
    getBidPeriod() {
      this.$axios.get(this.$api.sendBidPeriodPage, {
        params: {
          page: this.currentPage,
          pageSize: 99999,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.options = result.list.map((item) => {
            return {
              tendersId: item.tendersId, // 假设你想要保留tendersId 
              value: item.bidPeriod,
              label: item.bidPeriod
            };
          });
          this.selectLoading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })
    },
    // 缴费
    handleCommand(row) {
      // 使用params，路径不显示，与name搭配使用
      this.$router.push({ name: 'winBidDet', query: { tendersId: row.tendersId, depositAmount: row.depositAmount, id: row.id, sourceType: row.eligibleType } })
    },
    // 查看
    handleCommandPay(row) {
      let obj = {
        bidPeriod: this.bidPeriod,
        tendersName: this.tendersName,
        eligibleType: this.eligibleType,
        tendersLabel: this.tendersLabel,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)
      this.$router.push({ name: 'myBid', query: { label: row.tendersLabel } })
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      this.showFlag = false
    },
    // 级联选择搜索
    handleChange(val) {
      this.bidPeriod = val
      // console.log('级联选择', val);
      this.onCurrentPage()
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getRequest()
    },
    // 状态文字颜色
    getStatusColor(status) {
      switch (status) {
        case '0':
          return '#E35B5A';//未缴费
        default:
          return '';//已缴费
      }
    },

  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
